import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import LinkWordPress from 'components/LinkWordPress'

import s from './styles.module.css'

const Buttons = ({ buttons }) => {
  if (buttons.length < 1 || !buttons?.[0].link?.url) return null
  return (
    <div className={s.container}>
      {buttons.map(({ link }, i) => (
        <Fragment key={`${link.url}${i}`}>
          <LinkWordPress className={s.link} linkType="page" link={link} />
        </Fragment>
      ))}
    </div>
  )
}

Buttons.propTypes = {
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      link: PropTypes.shape({
        target: PropTypes.string,
        title: PropTypes.string,
        url: PropTypes.string,
      }),
    })
  ),
}
Buttons.defaultProps = {
  buttons: [],
}

export default Buttons
