import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'

import Layout from 'components/Layout'
import SEO from 'components/SEO'
import SidebarMenu from 'components/SidebarMenu'
import PageSection from 'components/PageSection'

import s from './press-enquiries.module.css'

const PressEnquiries = ({ data }) => {
  const { acfPressEnquiries } = data.wordpressPage
  return (
    <Layout backgroundColor="#F2F2F2">
      <Helmet>
        <html data-theme-color="ebb" />
      </Helmet>
      <SEO />
      <div className={s.container}>
        <SidebarMenu activeLink="Press Enquiries" />
        <div className={s.items}>
          <PageSection data={acfPressEnquiries} />
        </div>
      </div>
    </Layout>
  )
}

PressEnquiries.propTypes = {
  data: PropTypes.shape({
    wordpressPage: PropTypes.shape({
      acfPressEnquiries: PropTypes.object,
    }),
  }),
}
PressEnquiries.defaultProps = {
  data: {},
}

export default PressEnquiries

export const pageQuery = graphql`
  query PressEnquiries {
    wordpressPage(uri: { eq: "/press-enquiries/" }) {
      acfPressEnquiries {
        title
        description
        buttons {
          link {
            target
            title
            url
          }
        }
      }
    }
  }
`
