import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import Buttons from 'components/Buttons'

import s from './styles.module.css'

const PageSection = ({ data, className }) => {
  const { title, description, wholesalebuttons, buttons } = data

  return (
    <div className={classNames(s.container, className)}>
      {title && (
        <h2 className={s.title} dangerouslySetInnerHTML={{ __html: title }} />
      )}
      {description && (
        <div
          className={s.description}
          dangerouslySetInnerHTML={{
            __html: description,
          }}
        />
      )}
      {(wholesalebuttons || buttons) && (
        <Buttons buttons={wholesalebuttons || buttons} />
      )}
    </div>
  )
}

PageSection.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    wholesalebuttons: PropTypes.arrayOf(
      PropTypes.shape({
        link: PropTypes.shape({
          target: PropTypes.string,
          title: PropTypes.string,
          uri: PropTypes.string,
        }),
      })
    ),
    buttons: PropTypes.arrayOf(
      PropTypes.shape({
        link: PropTypes.shape({
          target: PropTypes.string,
          title: PropTypes.string,
          uri: PropTypes.string,
        }),
      })
    ),
  }),
  className: PropTypes.string,
}
PageSection.defaultProps = {
  data: {},
  className: '',
}

export default PageSection
