import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Link } from 'gatsby'

import slugify from 'helpers/slugify'

import s from './styles.module.css'

const SidebarMenu = ({ activeLink, childLinks, className }) => {
  const links = [
    {
      label: 'Contact Us',
      slug: '/contact',
    },
    {
      label: `FAQ's`,
      slug: '/faqs',
    },
    {
      label: 'Press Enquiries',
      slug: '/press-enquiries',
    },
    {
      label: 'Terms & Conditions',
      slug: '/legal/terms-conditions',
    },
    {
      label: 'Privacy Policy',
      slug: '/legal/privacy-policy',
    },
  ]
  return (
    <div className={classNames(s.container, className)}>
      {links.map(({ label, slug }) => (
        <Fragment key={label}>
          <Link
            key={label}
            to={slug}
            className={classNames(s.link, {
              [s.linkActive]: label === activeLink,
            })}
          >
            {label}
          </Link>
          {label === activeLink && childLinks && (
            <div className={s.childLinks}>
              {childLinks.map(({ heading }) => (
                <a
                  key={heading}
                  href={`#legal-${slugify(heading)}`}
                  className={s.childLink}
                >
                  {heading}
                </a>
              ))}
            </div>
          )}
        </Fragment>
      ))}
    </div>
  )
}

SidebarMenu.propTypes = {
  activeLink: PropTypes.string,
  childLinks: PropTypes.array,
  className: PropTypes.string,
}

SidebarMenu.defaultProps = {
  activeLink: '',
  childLinks: [],
  className: '',
}

export default SidebarMenu
